
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueParallaxJs from 'vue-parallax-js'
import VueNumber from 'vue-number-animation'
import checkView from 'vue-check-view'
import VueMeta from 'vue-meta'
import VueAnalytics from 'vue-analytics'
import "fullpage.js/dist/fullpage.css"
import VueFullPage from 'vue-fullpage.js'
import VuePlayer from '@iomariani/vue-player'
import i18n from './i18n'

Vue.component('vue-player', VuePlayer)
// add vue-parallax-js to vue
Vue.use(VueParallaxJs)
Vue.use(checkView)
Vue.use(VueNumber)
Vue.use(VueMeta)
Vue.config.productionTip = false
Vue.use(VueFullPage);

Vue.use(VueAnalytics, { 
  id: 'G-F78KEGSVPK', 
  router 
}),

new Vue({
  router,
  store,
  i18n,

  // i18n,
  render: h => h(App),
}).$mount('#app')
