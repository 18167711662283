<template>
  <div id="app">
    <Loader/>
    <Header/>
    <router-view></router-view>
    <Bandeau/>
    <Footer/>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Header: () => import('@/components/Header.vue'),
    Bandeau: () => import('@/components/Bandeau.vue'),
    Footer: () => import('@/components/Footer.vue'),
    Loader: () => import('@/components/Loader.vue'),
  },
  props: {
    msg: String
  },

  mounted () {

    // setTimeout(function(){
    //   document.querySelector('.custom-pointer').classList.remove('hideAfterLoad');
    //   document.querySelector('#mouseanim').classList.remove('hideAfterLoad');
    // },1000)
  },
  computed: mapState(['locale']),
  watch: {
    locale() {
      this.$router.replace({ params: { lang: this.locale } }).catch(() => {})
    }
  },
  created() {
    this.$store.dispatch('changeLocale', this.$store.state.locale)
  }

}
</script>


<style lang="scss">
  @import '@/assets/scss/main.scss';
</style>
