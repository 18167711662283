import Vue from 'vue'
import VueRouter from 'vue-router'
import { languages } from '../i18n'
import store from '@/store'

Vue.use(VueRouter)

function lazyLoad(view){
  return() => import(`@/components/${view}.vue`)
}

const routes = [
   {
      path: '/',
      name: 'root',
      beforeEnter(to, from, next) {
         next(store.state.locale)
      }
   },
   {
      path: '/:lang',
      component: {
         render: h => h('router-view')
       },
      beforeEnter(to, from, next) {
        let lang = to.params.lang
        if (languages.includes(lang)) {
          if (store.state.locale !== lang) {
            store.dispatch('changeLocale', lang)
          }
          return next()
        }
        return next({ path: store.state.locale })
      },
      children: [
         { path: '/:lang', component: lazyLoad('Home'), name: 'Home' },
         { path: '/:lang/saint-ouen', component: lazyLoad('SaintOuen'), name : 'SaintOuen' },
         { path: '/:lang/entry-harmony', component: lazyLoad('EntrerHarmony'), name : 'EntrerHarmony' },
         { path: '/:lang/contact', component: lazyLoad('Contact'), name : 'Contact' },
         { path: '/:lang/telechargement', component: lazyLoad('Telechargement'), name : 'Telechargement' },
         { path: '/:lang/traite-harmony', component: lazyLoad('TraiteHarmony'), name : 'TraiteHarmony' },
         { path: '/:lang/mentions-legales', component: lazyLoad('MentionsLegales'), name : 'MentionsLegales' },
         { path: "/:lang/:pathMatch(.*)*", component: lazyLoad('PageNotFound') }
      ]
   }
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes,
   
   scrollBehavior: (to) => {
      /**GESTION ANCRAGE */
      function anchor(){
         var path_url = window.location.pathname
         var Ishash = to.hash

         //**** ENCRAGE MOBILE  ****** */
         if(screen.width < 1025){
            if (Ishash != 0) {
               setTimeout(() => {
                  document.querySelector(to.hash).scrollIntoView({ behavior: 'instant', block: 'start'});
               }, 1000);
            }else if(document.querySelector("#first-Page")){
               setTimeout(() => {
                  document.querySelector("#first-Page").scrollIntoView({ behavior: 'instant', block: 'start'});
               }, 200);
            }else{
               setTimeout(() => {
                  return window.scrollTo({
                     top: 0, 
                     behavior: 'instant'
                  })
               }, 200);
            }
         }
   
         //**** ENCRAGE SAINT-OUEN  ***** */
         else if(path_url == "/fr/saint-ouen" || path_url == "/en/saint-ouen"){
            if(to.hash){
               setTimeout(() => {
                  document.querySelector(to.hash).scrollIntoView({ behavior: 'instant', block: 'start'});
               }, 500);
               
            }else{
               setTimeout(() => {
                  return window.scrollTo({
                     top: 0, 
                     behavior: 'instant'
                  })
               }, 500)
            }
         }

         //**** ENCRAGE ENTRY HARMONY  ***** */
         else if(path_url == "/fr/entry-harmony" || path_url == "/en/entry-harmony"){
            let compReady = setInterval(() => {
               if (document.querySelector(".fp-slidesNav")) {
                  switch (to.hash) {
                     case "":
                        document.querySelector("a[href='#section-first']").click()
                     break;

                     case "#dynamique-services":
                        document.querySelector("a[href='#dynamique-services']").click()
                     break;

                     case "#geste-precis":
                        document.querySelector("a[href='#precis-geste']").click()
                     break;

                     case "#vivre-harmony":
                        document.querySelector("a[href='#vivre-harmony']").click()
                        break;

                     case "#roof-top":
                        document.querySelector("a[href='#roof-top']").click()
                        break;
                     
                     default:
                     break;
                  }
               }
               clearInterval(compReady);
            }, 500);
         }
      }

      /**RUN IF DOM IS READY */
      setTimeout(() => {
         anchor()
      }, 800);
   }
})

export default router
